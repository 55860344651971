import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import tastyImage from "../images/slider-center.gif"
import sliderImg from "../images/slider.gif"


import bread from "../images/mini-banner/bread-banner-2.jpg"
import miniBites from "../images/mini-banner/mini-bites-banner.jpg"
import delights from "../images/mini-banner/k-delights-3.jpg"
import drinks from "../images/mini-banner/k-drinks.jpg"



const IndexPage = () => (
  <Layout>
    <SEO description="Kabuhayan Cafe comprises of various Filipino breads which is under our Bread Box, and native Filipino Special Delicatessen to cater to all Filipinos in the UAE as a whole." />
    <div className="slider container mx-auto mt-20 xl:w-9/12 lg:w-9/12 md:w-9/12 w-full">
      <img src={sliderImg} alt="" />
    </div>
    <div className="tasty--offers container mx-auto mt-20">
      <h2 className="text-center font-title tasty--offers__title">Daily Tasty Offers</h2>
      <div className="flex flex-wrap">
        <div className="w-full md:w-full lg:w-1/3 mt-10 product--lists">
          <h3 className="text-center font-title product--lists__title mb-3">K's Bread Box</h3>
          <img src={bread} alt="Bread Box" className="mb-10" />
          <h3 className="text-center font-title product--lists__title mb-3">K's Mini Bites</h3>
          <img src={miniBites} alt="Bread Box" className="mb-10" />
        </div>
        <div className="w-full lg:px-10 lg:m-10 lg:w-1/3 lg:mx-0 md:w-full m-0 mb-0 px-0">
          <img src={tastyImage} alt="" className="hidden md:block mx-auto" />
          <div className="quote-box mt-5">
            <h3 className="text-center font-title hidden md:block">“Every bite takes you home”</h3>
            <Link to="/menu" className="text-center btn block bg-violet p-5 rounded mt-6">VIEW ALL OUR TASTY MENU</Link>
          </div>
        </div>
        <div className="w-full md:w-full lg:w-1/3 mt-10 product--lists">
          <h3 className="text-center font-title product--lists__title mb-3">K's Delight</h3>
          <img src={delights} alt="Bread Box" className="mb-10" />
          <h3 className="text-center font-title product--lists__title mb-3">K's Drinks & Beverages</h3>
          <img src={drinks} alt="Bread Box" className="mb-10" />
        </div>
      </div> {/* END div.flex*/}
    </div>
    <div className="our-story container mx-auto my-20 mt-10">
      <h2 className="text-center font-title our-story__title">Our Story</h2>

      <p className="our-story__desc mt-12 px-10 text-center leading-loose">
        At Kabuhayan, just like the name every bite is a mouthful.
      </p>
      <p className="our-story__desc mt-5 px-10 text-center leading-loose">
        It all started when our friends and family gathered around the table for some soul food and realized the lack of authentic Filipino quick bites. We aim to prepare and serve quality bread and Miryendas that will remind you of home with every taste.
      </p>
      <p className="mt-10 our-story__signature__author font-title text-center italic">Kabuhayan Cafe</p>
    </div>
    {/*   <div className="customer-feedback container mx-auto mt-32 mb-20">
      <h2 className="text-center font-title customer-feedback__title">Customer Feedback</h2>
      <div className="flex flex-wrap justify-between my-16">
        <div className="feedback--list w-full md:w-1/2 flex px-8">
          <img src={feedbackImg1} alt="" className="w-1/3 mr-5 object-contain" />
          <div className="feeedback--list__box py-5 flex flex-col items-start justify-center">
            <p className="feedback--list__name">Lorem Ipsum, Philippines</p>
            <p className="feedback--list__quote font-title italic mt-2">
              “Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam”
            </p>
            <img src={feedbackStars} alt="" className="w-1/3 mt-3 active" />
          </div>
        </div>
        <div className="feedback--list w-full md:w-1/2 flex px-8">
          <img src={feedbackImg1} alt="" className="w-1/3 mr-5 object-contain" />
          <div className="feeedback--list__box py-5 flex flex-col items-start justify-center">
            <p className="feedback--list__name">Lorem Ipsum, Philippines</p>
            <p className="feedback--list__quote font-title italic mt-2">
              “Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam”
            </p>
            <img src={feedbackStars} alt="" className="w-1/3 mt-3 active" />
          </div>
        </div>
      </div>
</div> */}

  </Layout>
)

export default IndexPage
